import React from "react"
import PropTypes from "prop-types"
import {Link, graphql} from "gatsby"
import Layout from '../../components/layout';
import Helmet from "react-helmet"
import Seo from '../../components/seo';

const tagTemplate = ({pageContext, data}) => {
    const {tag} = pageContext
    const {edges, totalCount} = data.allMarkdownRemark
    const tagHeader = `${totalCount} post${
        totalCount === 1 ? "" : "s"
        } tagged with "${tag}"`

    return (
        <Layout>
            <Seo title={tagHeader}/>
            <Helmet>
                <meta name="robots" content='noindex,follow' />
            </Helmet>
            <section className="flex height-half blog intro include-navigation-space">
                <h1>{tagHeader}</h1>
            </section>
            <section className="blog content">
                <div className="flex-left">
                    {edges.map(({node}) => {
                        const {path} = node.frontmatter
                        const {title} = node.frontmatter
                        return (
                            <h3 key={path}>
                                <Link to={path}>{title}</Link>
                            </h3>
                        )
                    })}
                </div>
            </section>
        </Layout>
    )
}

tagTemplate.propTypes = {
    pageContext: PropTypes.shape({
        tag: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            totalCount: PropTypes.number.isRequired,
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    node: PropTypes.shape({
                        frontmatter: PropTypes.shape({
                            title: PropTypes.string.isRequired,
                        }),
                    }),
                }).isRequired
            ),
        }),
    }),
}

export default tagTemplate

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`