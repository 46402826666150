import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import {useStaticQuery, graphql} from "gatsby"

function SEO({description, lang, meta, link, title, image, canonical}) {
    let linkData = [];

    const {site} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `
    )

    const metaDescription = description || site.siteMetadata.description;
    const metaTitle = title + ' | ' + metaDescription;

    if(canonical) {
        linkData = [
            {
                rel: `canonical`,
                href: canonical,
            },
        ]
    }

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            image={site.siteMetadata.title}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.description}`}
            link={linkData.concat(link)}
            meta={[
                {
                    name: `keywords`,
                    content: 'magento freelancer switzerland, magento freelancer schweiz, magento freelancer bern, magento community, community maintainer, core contributor, magento 2, magento expert, e-commerce freelancer, magento upgrade, magento schweiz, magento bern, portfolio, professional, top contributor',
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: metaTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: metaTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `twitter:image`,
                    content: image,
                },
            ].concat(meta)}
            />
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    link: [],
    description: ``,
    image: ``,
    canonical: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    image: PropTypes.string,
    lang: PropTypes.string,
    canonical: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    link: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
