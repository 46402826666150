import React from 'react'

class Section extends React.Component {
    render() {
        const {children} = this.props
        return (
            <section className={this.props.class} id={this.props.id}>
                {children}
            </section>
        )
    }
}

export default Section
