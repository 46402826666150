import React from "react";
import Layout from '../components/layout';
import Section from '../components/section';
import Mailto from 'react-protected-mailto';
import Image from "../components/image";
import Social from "../components/social";
import Seo from "../components/seo"
import Awards from "../partials/awards"
import Clients from "../partials/clients"

class Index extends React.Component {
    render() {
        return (
            <Layout>
                <Seo title="Hello"/>
                <Section class="flex height-fix intro" id="intro">
                    <div className="flex">
                        <h3><strong>Hello, my name is <span
                            className="highlight-text nowrap">Marcel Hauri</span>.</strong></h3>
                        <p>I'm an <strong>award-winning</strong> Magento developer and <span
                            className="highlight-background nowrap">e-commerce specialist</span>.</p>
                        <p>
                            <a href="#about" className="button">Learn more</a>
                        </p>
                    </div>
                </Section>
                <Section class="flex about" id="about">
                    <div className="flex limit">
                        <h3>About me</h3>
                        <Image filename="me.jpg" alt="Marcel Hauri"/>
                        <p>I don't like defining myself through the work I've done. I define myself through the work I
                            want to do. Abilities can be imparted, personality is innate. I prefer to continue learning,
                            to challenge myself and to do interesting things that are important.</p>
                    </div>
                </Section>
                <Section class="flex clients" id="clients">
                    <div className="flex">
                        <h3>Clients</h3>
                        <div className="flex row list">
                            <Clients/>
                        </div>
                    </div>
                </Section>
                <Section class="flex limit awards" id="awards">
                    <div className="flex">
                        <h3>Awards</h3>
                        <Awards/>
                    </div>
                </Section>
                <Section class="flex contact" id="get-in-touch">
                    <div className="flex limit">
                        <h3>Get in touch</h3>
                        <div className="flex">
                            <p>If you have a problem that needs to be solved, an online store that needs to be rescued
                                or just want to say hello, send me an email.</p>
                            <Mailto
                                email='hello@marcelhauri.ch'
                                headers={
                                    {subject: "Let's talk"}
                                }
                                className="button normal"/>
                            <br/>
                        </div>
                    </div>
                    <div className="flex social">
                        <div className="flex row">
                            <Social url="https://twitter.com/mhauri" icon="twitter" title="Twitter"/>
                            <Social url="https://www.facebook.com/mhauri" icon="facebook" title="Facebook"/>
                            <Social url="https://github.com/mhauri" icon="github" title="Github"/>
                            <Social url="https://stackoverflow.com/users/900326/mhauri" icon="stack-overflow"
                                    title="Stack Overflow"/>
                            <Social url="https://www.linkedin.com/in/marcelhauri" icon="linkedin" title="LinkedIn"/>
                            <Social url="https://www.commercehero.io/mhauri" icon="commercehero" title="Commerce Hero"/>
                        </div>
                    </div>
                </Section>
            </Layout>
        )
    }
}

export default Index
