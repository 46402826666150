import React from 'react'
import {Link} from "gatsby"

class Navigation extends React.Component {
    render() {
        return (
            <div className="navigation">
                <div className="logo">
                    <Link to="/"><span className="highlight-text nowrap">Marcel Hauri</span></Link>
                </div>
                <nav className="menu">
                    <ul>
                        <li><Link to="/blog">Blog</Link></li>
                    </ul>
                </nav>
            </div>
        )
    }
}

export default Navigation
