import React from 'react'
import Mailto from 'react-protected-mailto';

const Address = props => {
    return (
        <>
            <p>Marcel Hauri<br/>
                Dennigkofenweg 75a<br/>
                3073 Gümligen<br/>
                Switzerland<br />
                <br />
                Phone: <a href="tel:+41794549439">+41 79 454 94 39</a><br />
                E-Mail: <Mailto
                    email='hello@marcelhauri.ch'
                    headers={
                        {subject: "Let's talk"}
                    }/>
            </p>
        </>
    )
}

export default Address
