import React from "react"
import Freelancer from "../partials/freelancer"

class MagentoFreelancer extends React.Component {
    render() {
        return (
            <Freelancer title='Magento Freelancer Schweiz' canonical='https://magento.marcelhauri.ch/magento-freelancer'/>
        )
    }
}

export default MagentoFreelancer
