// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-item-template-js": () => import("/opt/build/repo/src/templates/blog/itemTemplate.js" /* webpackChunkName: "component---src-templates-blog-item-template-js" */),
  "component---src-templates-blog-tag-template-js": () => import("/opt/build/repo/src/templates/blog/tagTemplate.js" /* webpackChunkName: "component---src-templates-blog-tag-template-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-impressum-js": () => import("/opt/build/repo/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-magento-freelancer-bern-js": () => import("/opt/build/repo/src/pages/magento-freelancer-bern.js" /* webpackChunkName: "component---src-pages-magento-freelancer-bern-js" */),
  "component---src-pages-magento-freelancer-schweiz-js": () => import("/opt/build/repo/src/pages/magento-freelancer-schweiz.js" /* webpackChunkName: "component---src-pages-magento-freelancer-schweiz-js" */),
  "component---src-pages-magento-freelancer-switzerland-js": () => import("/opt/build/repo/src/pages/magento-freelancer-switzerland.js" /* webpackChunkName: "component---src-pages-magento-freelancer-switzerland-js" */),
  "component---src-pages-magento-freelancer-js": () => import("/opt/build/repo/src/pages/magento-freelancer.js" /* webpackChunkName: "component---src-pages-magento-freelancer-js" */)
}

