import React from "react"
import { Link, graphql } from "gatsby"
import Layout from '../components/layout'
import Section from '../components/section'
import Seo from "../components/seo"

class Blog extends React.Component {
    render() {
        const { data } = this.props
        const posts = data.allMarkdownRemark.edges;

        return (
            <Layout>
                <Seo title="Blog"/>
                <Section class="flex height-half intro include-navigation-space">
                    <h1>Blog</h1>
                </Section>
                <Section class="flex blog" id="blog">
                    <div className="flex-left limit">
                        {posts.map(({ node }) => {
                            return (
                                <div key={node.frontmatter.path}>
                                    <h2>
                                        <Link style={{ boxShadow: `none` }} to={node.frontmatter.path}>
                                            {node.frontmatter.title}
                                        </Link>
                                    </h2>
                                    <p className="small">
                                        {node.frontmatter.date}, by {node.frontmatter.author.name}
                                    </p>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: node.excerpt,
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </Section>
            </Layout>
        )
    }
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          frontmatter {
            path
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            author {
                name
                twitter
            }
          }
        }
      }
    }
  }
`