import React from "react"
import Layout from '../components/layout'
import Section from '../components/section'
import Seo from "../components/seo"
import Image from "../components/image";
import Clients from "../partials/clients"
import Address from "../partials/adress"

const Freelancer = props => {
    return (
        <Layout>
            <Seo title={props.title} canonical={props.canonical}/>
            <Section class="flex height-half intro include-navigation-space">
                <h1>{props.title}</h1>
                <p>
                    <a href="#get-in-touch" className="button">Get in touch</a>
                </p>
            </Section>
            <Section class="flex freelancer about" id="freelancer">
                <div className="flex limit">
                    <p><strong>Award-winning Magento developer and e-commerce specialist</strong></p>
                    <Image filename="me.jpg" alt="Marcel Hauri"/>

                    <p>PHP for 20 years and Magento backend development since 2014. Certified Magento Developer &
                        Certified Magento 2 Solution Specialist. Active Magento Community Maintainer and Magento 2
                        Core Contributor.</p>

                    <p>I worked on different Magento projects including conception and architecture of e-commerce
                        solutions, interfaces, imports/exports, speed optimization, API integration and
                        shipment/payment integrations.</p>

                    <p>I was awarded with the Magento Community Spirit Award and was listed among the top 50
                        contributors in 2018.</p>

                    <p>As a Magento freelancer and award-winning Magento developer I support agencies, merchants and
                        shop owners with small and large projects in the following areas:</p>
                    <ul>
                        <li><strong>Mangento Configuration</strong>
                            <ul>
                                <li>Taxes</li>
                                <li>Shipping</li>
                                <li>Payment systems</li>
                                <li>etc.</li>
                            </ul>
                        </li>
                        <br />
                        <li><strong>Magento programming</strong>
                            <ul>
                                <li>Themes and Templates</li>
                                <li>Modules and extensions</li>
                                <li>ERP integrations</li>
                                <li>Import / Export</li>
                                <li>Migration to Magento 2</li>
                                <li>etc.</li>
                            </ul>
                        </li>
                        <br />
                        <li><strong>Magento Optimization</strong>
                            <ul>
                                <li>Page speed</li>
                                <li>Code quality</li>
                                <li>etc.</li>
                            </ul>
                        </li>
                        <br />
                        <li><strong>Magento SEO</strong>
                            <ul>
                                <li>Search engine optimization</li>
                                <li>Campaign optimisation</li>
                                <li>etc.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </Section>
            <Section class="flex clients" id="clients">
                <div className="flex">
                    <h3>Clients</h3>
                    <div className="flex row list">
                        <Clients/>
                    </div>
                </div>
            </Section>
            <Section class="flex contact" id="get-in-touch">
                <div className="flex limit">
                    <h3>Get in touch</h3>
                    <div className="flex">
                        <Address/>
                    </div>
                </div>
            </Section>
        </Layout>
    )
}

export default Freelancer