import React from "react"
import { Link } from "gatsby"
import Layout from '../components/layout'
import Section from '../components/section'
import Seo from "../components/seo"
import Address from "../partials/adress"

class Impressum extends React.Component {
    render() {
        return (
            <Layout>
                <Seo title="Impressum"/>
                <Section class="flex height-half intro include-navigation-space">
                    <h1>Impressum</h1>
                </Section>
                <Section class="flex impressum" id="impressum">
                    <div className="flex-left limit">
                        <p><strong>Responsible for the content of this site</strong></p>

                        <Address/>

                        <p>This is a personal site. Any views or opinions represented in this site are personal and
                            belong solely to the site owner and do not represent those of people, institutions or
                            organizations that the owner may or may not be associated with in professional or personal
                            capacity, unless explicitly stated. Any views or opinions are not intended to malign any
                            religion, ethnic group, club, organization, company, or individual.</p>

                        <p>All content provided on this site is for informational purposes only. The owner of this site
                            makes no representations as to the accuracy or completeness of any information on this site
                            or found by following any link on this site. The owner will not be liable for any errors or
                            omissions in this information nor for the availability of this information. The owner will
                            not be liable for any losses, injuries, or damages from the display or use of this
                            information.</p>
                        <p><strong>Downloadable Files</strong></p>
                        <p>Any downloadable file, including but not limited to pdfs, docs, jpegs, pngs, is provided at
                            the user’s own risk. The owner will not be liable for any losses, injuries, or damages
                            resulting from a corrupted or damaged file.</p>
                    </div>
                    <div className="flex">
                        <Link to="/" className="button">Back to site</Link>
                    </div>
                </Section>
            </Layout>
        )
    }
}

export default Impressum
