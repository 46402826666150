import React from 'react'
import Client from "../components/client";

const Clients = props => {
    return (
        <>
            <Client filename="clients/bdk.png" alt="BDK Luminaires SA"/>
            <Client filename="clients/hajk.png" alt="hajk Scout & Sport AG"/>
            <Client filename="clients/furrerfrey.png" alt="Furrer+Frey AG"/>
            <Client filename="clients/ineltro.png" alt="Ineltro AG"/>
            <Client filename="clients/ribag.png" alt="Ribag AG"/>
            <Client filename="clients/kvt.png" alt="KVT Fastening AG"/>
            <Client filename="clients/landi.png" alt="LANDI Schweiz AG"/>
            <Client filename="clients/gimota.png" alt="Gimota AG"/>
            <Client filename="clients/otto-shoch.png" alt="Otto Schoch AG"/>
            <Client filename="clients/klotz-ais.png" alt="Klotz AIS"/>
            <Client filename="clients/semadeni.png" alt="Semadeni AG"/>
            <Client filename="clients/mechafin.png" alt="Mechafin AG"/>
            <Client filename="clients/jungfrau.png" alt="Jungfrau"/>
            <Client filename="clients/jlc.png" alt="Jaeger-LeCoultre"/>
        </>
    )
}

export default Clients
