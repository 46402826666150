import React from "react"
import { Link } from "gatsby"
import Layout from '../components/layout'
import Section from '../components/section'
import Seo from "../components/seo"

class NotFound extends React.Component {
    render() {
        return (
            <Layout>
                <Seo title="404 Page not found"/>
                <Section class="flex height-fix intro">
                    <div className="flex">
                        <h3><strong>Oh no, <span
                            className="highlight-text">did you get lost?</span></strong></h3>
                        <p>
                            <Link to="/" className="button">Show me the way</Link>
                        </p>
                    </div>
                </Section>
            </Layout>
        )
    }
}

export default NotFound
