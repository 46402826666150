import React from "react"
import { DiscussionEmbed } from "disqus-react";
import {graphql} from "gatsby"
import Layout from '../../components/layout';
import Helmet from "react-helmet"
import Seo from '../../components/seo';
import Section from '../../components/section';

class ItemTemplate extends React.Component {
    render() {
        const {data} = this.props;
        const {markdownRemark} = data;
        const {frontmatter, html} = markdownRemark;
        const disqusShortname = "mhauri";
        const disqusConfig = {
            identifier: frontmatter.path,
            title: frontmatter.title,
        };
        const publishDate = new Date(frontmatter.date).toISOString();
        return (
            <Layout>
                <Seo title={frontmatter.title}/>
                <Helmet>
                    <meta property="article:published_time" content={publishDate} />
                    <meta property="og:type" content="article" />
                </Helmet>
                <Section class="flex height-half blog intro include-navigation-space">
                    <h1>{frontmatter.title}</h1>
                </Section>
                <Section class="blog content">
                    <div className="flex-left" dangerouslySetInnerHTML={{__html: html}}/>
                    <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
                </Section>
            </Layout>
        )
    }
}

export default ItemTemplate

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt
      headings {
        depth
        value
       }
        
      frontmatter {
        date(formatString: "YYYY-MM-DDTHH:mm:ss")
        path
        title
        author {
          name
          twitter
          url
        }
      }
    }
  }
`