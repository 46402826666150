import React from "react"
import Freelancer from "../partials/freelancer"

class MagentoFreelancer extends React.Component {
    render() {
        return (
            <Freelancer title='Magento Freelancer'/>
        )
    }
}

export default MagentoFreelancer
