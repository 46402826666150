import React from 'react'

const Social = props => {
    const iconclass = "icon " + props.icon;
    const title = "Follow me on " + props.title
    return (
        <a href={props.url} rel="noopener noreferrer nofollow" title={title} className={iconclass}>
            <span>{props.title}</span>
        </a>
    )
}

export default Social
