import React from 'react'
import Award from "../components/award";

const Awards = props => {
    return (
        <>
            <Award url="https://magento.com/blog/events/announcing-2019-community-spirit-award-winners"
                   title="Magento Community Spirit Award"
                   slug="2019 / Magento, an Adobe Company"
                   color="#000000"
            />
            <Award url="https://magento.com/blog/technical/thank-you-top-50-contributors-2018"
                   title="Magento Top 50 Contributors"
                   slug="2018 / Magento, an Adobe Company"
                   color="#000000"
            />
        </>
    )
}

export default Awards
