import React from 'react'
import { Link } from "gatsby";
import Navigation from './navigation';
import '../assets/scss/main.scss'

class Layout extends React.Component {
    render() {
        const {children} = this.props

        return (
            <>
                <Navigation/>
                <div className="wrapper">
                    {children}
                </div>
                <div className="flex footer">
                    <p>&copy; 2019 Marcel Hauri | <Link to="/impressum" className="impressum">Impressum</Link> | <Link to="/magento-freelancer" className="freelancer">Freelancer</Link></p>
                </div>
            </>
        )
    }
}

export default Layout
